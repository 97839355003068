<template>
  <v-container
    class="my-4"
    :style="
      `width: ${
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? ''
          : '1200px !important'
      }; background-color: #fff;`
    "
  >
    <v-row justify="center">
      <v-col cols="12" md="12">
        <h2 class="mb-2">{{ $t("CHÍNH SÁCH GIẢI QUYẾT KHIẾU NẠI") }}</h2>
        <div class="text-left">
          <p>
            Conasi và Nhà cung cấp luôn có trách nhiệm tiếp nhận và xử lý khiếu
            nại của Khách hàng liên quan đến giao dịch tại website CONASI365.vn.
          </p>
          <p>
            Khách hàng có quyền gửi khiếu nại về việc chất lượng sản phẩm, dịch
            vụ không đúng với mô tả, về vấn đề đặt lịch/đặt phòng, về vấn đề
            thanh toán đến Ban Quản trị của website CONASI365.vn. Thành viên nên
            gửi khiếu nại ngay trong vòng 03 ngày làm việc kể từ khi phát sinh
            sự việc. Đối với các khiếu nại khác, Thành viên có thể gửi ý kiến để
            góp phần cho website CONASI365.vn tốt hơn khi phát hiện có vấn đề
            chưa rõ ràng hay hành vi có dấu hiệu vi phạm. Khi tiếp nhận những
            phản hồi này, Conasi sẽ xác nhận lại thông tin, trường hợp đúng như
            phản ánh của Khách hàng tùy theo mức độ, Conasi sẽ có những biện
            pháp xử lý kịp thời.
          </p>
        </div>
        <div class="text-left">
          <p class="font-weight-bold">Các cách thức gửi khiếu nại như sau:</p>
          <p>(i) Gửi thư điện tử đến địa chỉ email: info@conasi365.vn</p>
          <p>(ii) Gọi điện thoại đến Hotline: 1900 63 68 70</p>
          <p>
            (iii) Gửi khiếu nại trực tiếp (Khách hàng đến trực tiếp hoặc gửi
            khiếu nại thông qua đường bưu điện) đến địa chỉ:
          </p>
          <ul>
            <li>
              Tên đơn vị: Công Ty Cổ Phần Quản Lý Và Phát Triển Bất Động Sản
              Conasi
            </li>
            <li>Địa chỉ: 58 Nguyễn Đình Chiểu, P Đa Kao, Quận 1, TPHCM</li>
          </ul>
        </div>
        <div class="text-left">
          <p class="font-weight-bold mt-4">
            Trình tự giải quyết tranh chấp, khiếu nại:
          </p>
          <p>
            <span class="font-weight-bold font-italic">- Bước 1:</span> Bộ phận
            Chăm Sóc Khách Hàng sẽ tiếp nhận các khiếu nại của Thành viên được
            gửi đúng theo các phương thức nêu trên.
          </p>
          <ul>
            <li>
              Conasi sẽ yêu cầu Khách hàng và/hoặc Nhà cung cấp cung cấp các
              thông tin, bằng chứng liên quan đến giao dịch, sản phẩm để xác
              minh, làm rõ vụ việc và có hướng xử lý thích hợp.
            </li>
            <li>
              Conasi đối chiếu thông tin trên hệ thống (lịch sử xuất voucher, mã
              số, ngày giờ, nhà cung cấp, đợt phát hành, …) và đối chiếu với
              thông tin thanh toán của Bộ phận Kế toán.
            </li>
          </ul>
          <p class="mt-4">
            <span class="font-weight-bold font-italic">- Bước 2:</span> Bộ phận
            Chăm Sóc Khách Hàng sẽ trả lời/hồi đáp dựa trên các chính sách mà
            Conasi đã công bố. Cách thức trả lời thông qua email hoặc điện thoại
            đến thành viên đó.
          </p>
          <p>
            <span class="font-weight-bold font-italic">- Bước 3:</span> Chuyển
            kết quả trả lời/hồi đáp khiếu nại cho các bộ phận có liên quan nếu
            (a) phải làm việc với nhà cung cấp để đảm bảo cung cấp dịch vụ cho
            khách hàng, (b) hoàn tiền cho thành viên và (c) các trường hợp khác
            cần hỗ trợ từ các bộ phận liên quan để Conasi giải quyết quyền lợi
            cho thành viên.
          </p>
        </div>
        <div class="text-left">
          <p class="font-weight-bold mt-4">Thời hạn xử lý khiếu nại</p>
          <p>
            Thời gian tiếp nhận khiếu nại là 02 - 03 ngày làm việc. Thời gian
            trả lời/hồi đáp đối với khiếu nại tối đa là 15 ngày làm việc kể từ
            ngày tiếp nhận khiếu nại. Nếu thành viên khiếu nại chưa cung cấp đủ
            thông tin theo yêu cầu của trong thời hạn để giải quyết khiếu nại,
            Conasi sẽ hiểu rằng thành viên đó đã từ bỏ việc khiếu nại và vụ việc
            sẽ được đóng lại khi kết thúc thời hạn 15 ngày làm việc nêu trên mà
            không có lỗi của Conasi.
          </p>
          <p>
            Trong trường hợp có yêu cầu của thành viên đưa vụ việc ra cơ quan
            Nhà Nước có thẩm quyền để giải quyết theo pháp luật, thời hạn xử lý
            sẽ theo thời hạn giải quyết của cơ quan nhà nước có thẩm quyền.
          </p>
        </div>
        <div class="text-left">
          <p class="font-weight-bold mt-4">Trách nhiệm mỗi bên</p>
          <p>
            Các bên bao gồm Nhà cung cấp, Khách hàng và Conasi sẽ phải có vai
            trò trách nhiệm trong việc tích cực giải quyết vấn đề:
          </p>
          <ul>
            <li>
              Đối với Nhà cung cấp, cần có trách nhiệm cung cấp văn bản giấy tờ
              chứng thực thông tin liên quan đến sự việc đang gây mâu thuẫn cho
              khách hàng;
            </li>
            <li>
              Đối với Conasi sẽ lắng nghe và tiếp nhận thông tin từ khách hàng
              (trong trường hợp cụ thể chúng tôi sẽ: yêu cầu khách hàng cần có
              trách nhiệm cung cấp chính xác các thông tin vô hình và hữu hình
              về vấn đề mâu thuẫn đang phát sinh cần giải quyết mà khách hàng đã
              tự thấy mình bị thiệt hại) và Nhà cung cấp, sau đó tiến hành xem
              xét và nêu rõ, phân tích lỗi thuộc về bên nào. Lấy ý kiến về sự
              thỏa thuận mức độ bồi hoàn của hai bên và kết thúc giải quyết
              tranh chấp một cách thỏa đáng nhất.
            </li>
            <li>
              Đối với Khách hàng: cung cấp đầy đủ, chính xác các thông tin, bằng
              chứng liên quan đến giao dịch, sản phẩm theo yêu cầu của Conasi để
              xác minh, làm rõ vụ việc và có hướng xử lý thích hợp.
            </li>
          </ul>
        </div>
        <div class="text-left">
          <p class="font-weight-bold mt-4">Hướng xử lý</p>
          <p>
            Trong trường hợp chứng minh được lỗi thuộc về Nhà cung cấp, Conasi
            sẽ có biện pháp cảnh cáo, yêu cầu bồi hoàn lại toàn bộ chi phí mà
            Thành viên đã phải bỏ ra để sở hữu hàng hóa/dịch vụ đó hoặc phải đổi
            lại sản phẩm, dịch vụ đúng với chất lượng mà Nhà cung cấp thông báo
            tại website CONASI365.vn. Nếu Nhà cung cấp tái phạm, Conasi sẽ chấm
            dứt và gỡ bỏ toàn bộ những thông tin, chương trình khuyến mại của
            Nhà cung cấp đó trên website CONASI365.vn.
          </p>
          <p>
            Trường hợp Conasi và các bên đã nỗ lực giải quyết qua hình thức
            thương lượng, thỏa thuận mà vẫn không thể giải quyết được mâu thuẫn
            phát sinh từ giao dịch giữa Khách hàng và Nhà cung cấp, thì một
            trong hai bên Khách hàng và Nhà cung cấp sẽ có thể đưa vụ việc đến
            Cơ quan Nhà nước có thẩm quyền giải quyết nhằm đảm bảo lợi ích hợp
            pháp của các bên.
          </p>
          <p>
            Conasi tôn trọng và nghiêm túc thực hiện các quy định của pháp luật
            về bảo vệ quyền lợi của Khách hàng. Vì vậy, đề nghị các Nhà cung cấp
            trên website CONASI365.vn cung cấp đầy đủ, chính xác, trung thực và
            chi tiết các thông tin liên quan đến sản phẩm, dịch vụ. Mọi hành vi
            lừa đảo, gian lận trong kinh doanh đều bị lên án và phải chịu hoàn
            toàn trách nhiệm trước pháp luật.
          </p>
          <p>
            Conasi công khai cơ chế giải quyết các tranh chấp phát sinh trong
            quá trình giao dịch trên website CONASI365.vn. Khi Khách hàng mua
            hàng hóa hoặc dịch vụ phát sinh mâu thuẫn với Nhà cung cấp hoặc bị
            tổn hại lợi ích hợp pháp.
          </p>
          <p>
            Conasi công khai cơ chế và quy trình giải quyết tranh chấp đối với
            các bên liên quan là: giải quyết tranh chấp theo cơ chế trao đổi
            thỏa thuận thống nhất. Các bên liên quan sẽ thực hiện theo quy trình
            trao đổi gián tiếp qua điện thoại, xác nhận bằng email, nếu vẫn chưa
            thỏa thuận được thì sẽ giải quyết thông qua gặp trực tiếp để cụ thể
            hóa vấn đề, giải quyết triệt để vấn đề mâu thuẫn giữa các bên sao
            cho có lợi nhất.
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
};
</script>

<style></style>
